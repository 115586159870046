export default {
   data() {
       return {
            /**
             * 選択項目 
             */
            AREAS: [
                {
                    text: 'United States',
                    value: 'US',
                    children: [
                        { text: 'TMNA_NAPO', value: '91040' },
                        { text: 'TMNA_Hawaii', value: '91031' },
                        // { text: '', value: '' },
                    ]
                },
                {
                    text: 'Australia',
                    value: 'AU',
                    children: [
                        { text: 'TMCA', value: '98011' },
                    ]
                },
                {
                    text: 'Canada',
                    value: 'CA',
                    children: [
                        { text: 'TCI', value: '91025' },
                    ]
                },
                {
                    text: 'China',
                    value: 'CN',
                    children: [
                        { text: 'TMCI', value: '97078' },
                    ]
                },
                {
                    text: 'Belgium',
                    value: 'BE',
                    children: [
                        { text: 'TME', value: '94034' },
                    ]
                },
                {
                    text: 'Iraq',
                    value: 'IQ',
                    children: [
                        { text: 'Toyota Iraq', value: '96065' },
                    ]
                },
                {
                    text: 'Japan',
                    value: 'JP',
                    children: [
                        { text: 'TMC', value: '01000' },
                    ]
                // },
                // {
                //     text: 'China',
                //     value: 'CH',
                //     children: [
                //         { text: 'China (TPCS)', value: '97070' },
                //         { text: 'China (GTMC)', value: '97077' },
                //         { text: 'China (TMCI)', value: '97078' },
                //         { text: '', value: '' },
                //     ]
                }
            ],
            /**
             * 選択項目データフォーマット
             */
             DATA_FORMAT: { area: 'US', distOrCompany: 'dist', distcode: '', companycode: '', companyname: '' }
       }
   },
   methods: {
       /**
        * デフォルトデータの取得
        */
       loadDefault() {
           return this.DATA_FORMAT;
       },
       /**
        * 保存データの取得
        */
       load(key) {
           let data = localStorage.getItem(key);
           if (data) {
               return JSON.parse(data);
           } else {
               return this.loadDefault();
           }
       },
       /**
        * データ保存
        */
       save(key, data) {
           localStorage.setItem(key, JSON.stringify(data));
       }
   }
}